export const heightRange = [
  `3'0"`,
  `3'1"`,
  `3'2"`,
  `3'3"`,
  `3'4"`,
  `3'5"`,
  `3'6"`,
  `3'7"`,
  `3'8"`,
  `3'9"`,
  `3'10"`,
  `3'11"`,
  `4'0"`,
  `4'1"`,
  `4'2"`,
  `4'3"`,
  `4'4"`,
  `4'5"`,
  `4'6"`,
  `4'7"`,
  `4'8"`,
  `4'9"`,
  `4'10"`,
  `4'11"`,
  `5'0"`,
  `5'1"`,
  `5'2"`,
  `5'3"`,
  `5'4"`,
  `5'5"`,
  `5'6"`,
  `5'7"`,
  `5'8"`,
  `5'9"`,
  `5'10"`,
  `5'11"`,
  `6'0"`,
  `6'1"`,
  `6'2"`,
  `6'3"`,
  `6'4"`,
  `6'5"`,
  `6'6"`,
  `6'7"`,
  `6'8"`,
  `6'9"`,
  `6'10"`,
  `6'11"`,
  `7'0"`,
  `7'1"`,
  `7'2"`,
  `7'3"`,
  `7'4"`,
  `7'5"`,
  `7'6"`,
  `7'7"`,
  `7'8"`,
  `7'9"`,
  `7'10"`,
  `7'11"`,
  `8'0"`,
  `8'1"`,
  `8'2"`,
  `8'3"`,
  `8'4"`,
  `8'5"`,
  `8'6"`,
  `8'7"`,
  `8'8"`,
  `8'9"`,
  `8'10"`,
  `8'11"`,
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const dates = () => {
  const datesArray = [];
  for (let i = 1; i < 32; i++) {
    datesArray.push(i);
  }
  return datesArray;
};

export const years = (startYear) => {
  const currentYear = new Date().getFullYear();
  const years = [];
  startYear = startYear || 1930;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
};

export const questionnaire = [
  // {
  //   question: "Let's start with the basics. What's your date of birth?",
  //   answers: ["dob"],
  //   standardDemo: "dob",
  //   type: "date",
  // },
  {
    question: "Please select your sex assigned at birth",
    answers: [
      {
        answer: "Female",
        error: {
          header: `ED treatment is indicated for the treatment of ED in men.`,
          body: `We recommend seeing a healthcare professional in-person to discuss whether ED treatment may be right for you.`,
        },
      },
      {
        answer: "Male",
      },
    ],
    type: "radio",
    standardDemo: "sex",
  },
  {
    question: "What state are you in?",
    answers: ["states"],
    standardDemo: "state",
    type: "select",
  },
  {
    question: "Please enter your weight (lbs)",
    answers: ["freeText"],
    type: "freeText",
  },
  {
    question:
      "Do you ever have a problem getting or maintaining an erection that is rigid enough for sex?",
    answers: [
      {
        answer: "Yes - every time",
        showConditional: false,
      },
      {
        answer: "Yes - more than half the time",
        showConditional: false,
      },
      {
        answer: "Yes - on occasion",
        showConditional: false,
      },
      {
        answer: "Yes - but rarely",
        showConditional: false,
      },
      {
        answer:
          "I never have a problem getting or maintaining an erection for as long as I want",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question: "Do you get erections…",
    answers: [
      {
        answer: "When masturbating",
        showConditional: false,
      },
      {
        answer: "When you wake up",
        showConditional: false,
      },
      {
        answer: "Neither",
        showConditional: false,
      },
    ],
    type: "radio",
  },
  {
    question:
      "Have you ever been formally treated for ED or tried any medicines, vitamins, or supplements to treat it?",
    answers: [
      {
        answer: "Yes",
        showConditional: true,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question: "Please provide any details related to the treatment.",
    subText: "Include name, dosage, effectiveness, and any side effects.",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  {
    question:
      "Have you had a physical exam with a healthcare provider in the past 5 years?",
    answers: [
      {
        answer: "Yes, I am in good health",
        showConditional: false,
      },
      {
        answer: "Yes, but they found problems",
        showConditional: true,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question: "Please explain any issues during your last physical exam",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  {
    question: "Was your blood pressure taken in the past year?",
    answers: [
      {
        answer: "Yes",
        showConditional: true,
      },
      {
        answer: "No, I will get a new blood pressure measurement",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question: "What was the value? (Example: 115/72)",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  {
    question:
      "Please list any current medicines, vitamins or dietary supplements you take regularly.",
    subText: "Please include the dosage.",
    answers: ["selfReportedMeds"],
    type: "freeText",
    standardDemo: "selfReportedMeds",
    isConditional: false,
  },
  {
    question:
      "Do you take any of the following medicines? Select all that apply.",
    answers: [
      {
        answer:
          "Nitroglycerin spray, ointment, patches or tablets (Nitro-Dur, Nitrolingual, Nitrostat, Nitromist, Nitro-Bid, Transderm-Nitro, Nitro-Time, Deponit, Minitran, Nitrek, Nitrodisc, Nitrogard, Nitroglycn, Nitrol ointment, Nitrong, Nitro-Par)",
        showConditional: false,
        error: {
          header: `We recommend seeing a healthcare professional in-person to discuss whether ED medication may be right for you.`,
        },
      },
      {
        answer:
          "Isosorbide mononitrate, or isosorbide dinitrate (Isordil, Dilatrate, Sorbitrate, Imdur, Ismo, Monoket)",
        showConditional: false,
        error: {
          header: `We recommend seeing a healthcare professional in-person to discuss whether ED medication may be right for you.`,
        },
      },
      {
        answer: "Other medicines containing nitrates",
        showConditional: false,
        error: {
          header: `We recommend seeing a healthcare professional in-person to discuss whether ED medication may be right for you.`,
        },
      },
      {
        answer:
          "The alpha blocker medications: doxazosin (Cardura), prazosin (Minipress), terazosin (Hytrin)",
        showConditional: false,
        error: {
          header: `We recommend seeing a healthcare professional in-person to discuss whether ED medication may be right for you.`,
        },
      },
      {
        answer: "Riociguat (Adempas)",
        showConditional: false,
        error: {
          header: `We recommend seeing a healthcare professional in-person to discuss whether ED medication may be right for you.`,
        },
      },
      {
        answer: "None of these apply",
        showConditional: false,
      },
    ],
    isConditional: false,
    type: "checkBox",
  },
  {
    question: "Please list all known drug allergies:",
    answers: ["allergies"],
    type: "freeText",
    standardDemo: "allergies",
    isConditional: false,
  },
  {
    question: "Please list any prior medical conditions or serious problems:",
    answers: ["medicalConditions"],
    type: "freeText",
    standardDemo: "medicalConditions",
    isConditional: false,
  },
  {
    question:
      "Do any of the following cardiovascular risk factors apply to you? Select all that apply.",
    answers: [
      {
        answer: "Diabetes",
        showConditional: true,
        condId: "diabetes",
      },
      {
        answer: "High cholesterol",
        showConditional: true,
        condId: "cholesterol",
      },
      {
        answer: "High blood pressure",
        showConditional: true,
        condId: "highPressure",
      },
      {
        answer:
          "My father had a heart attack or heart disease at 55 years or younger",
        showConditional: false,
      },
      {
        answer:
          "My mother had a heart attack or heart disease at 65 year or younger",
        showConditional: false,
      },
      {
        answer: "None of these apply",
        showConditional: false,
      },
    ],
    type: "checkBox",
    isConditional: false,
  },
  {
    question: "Metaquestion",
    isConditional: true,
    subQuestions: [
      {
        question: "Tell us more about your diabetes.",
        subText:
          "When was your last Hemoglobin A1c checked and what was the value?",
        answers: ["freeText"],
        type: "freeText",
        isConditional: true,
        condId: "diabetes",
      },
      {
        question: "Tell us more about your high cholesterol",
        answers: ["freeText"],
        type: "freeText",
        isConditional: true,
        condId: "cholesterol",
      },
      {
        question: "Tell us more about your high blood pressure",
        answers: ["freeText"],
        type: "freeText",
        isConditional: true,
        condId: "highPressure",
      },
    ],
  },
  {
    question:
      "Do you have or have you previously been diagnosed with any of the following? Select all that apply.",
    answers: [
      {
        answer: "Prostate cancer",
        showConditional: true,
      },
      {
        answer: "Enlarged prostate (BPH)",
        showConditional: true,
      },
      {
        answer: "Kidney transplant or any condition affecting the kidney",
        showConditional: true,
      },
      {
        answer: "Multiple Sclerosis (MS) or similar disease",
        showConditional: true,
      },
      {
        answer: "Spinal injuries and/or paralysis",
        showConditional: true,
      },
      {
        answer: "Neurological diseases",
        showConditional: true,
      },
      {
        answer: "Stomach, intestinal, or bowel ulcers",
        showConditional: true,
      },
      {
        answer: "Heart arrhythmias (abnormal beating of the heart)",
        showConditional: true,
      },
      {
        answer:
          "Any acquired, congenital, or developmental abnormalities of the heart including heart murmurs",
        showConditional: true,
      },
      {
        answer: "None of these apply",
        showConditional: false,
      },
    ],
    type: "checkBox",
    isConditional: false,
  },
  {
    question: "Please tell us more about your diagnoses and treatment(s).",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
    showPreviousAnswers: true,
  },
  {
    question:
      "Do you experience any of the following cardiovascular symptoms? Select all that apply.",
    answers: [
      {
        answer:
          "Chest pain or shortness of breath when climbing 2 flights of stairs or walking 4 blocks",
        showConditional: false,
        hasWarning: true,
        warning: "This may affect your eligibility for a prescription",
      },
      {
        answer: "Chest pain or shortness of breath with sexual activity",
        showConditional: false,
        hasWarning: true,
        warning: "This may affect your eligibility for a prescription",
      },
      {
        answer: "Unexplained fainting or dizziness",
        showConditional: false,
        hasWarning: true,
        warning: "This may affect your eligibility for a prescription",
      },
      {
        answer: "Prolonged cramping of the legs with exercise",
        showConditional: true,
        hasWarning: true,
        warning: "This may affect your eligibility for a prescription",
      },
      {
        answer: "Abnormal heart beats or rhythms",
        showConditional: false,
        hasWarning: true,
        warning: "This may affect your eligibility for a prescription",
      },
      {
        answer: "None of these apply",
        showConditional: false,
      },
    ],
    type: "checkBox",
    isConditional: false,
  },
  {
    question: "Please tell us more about your prolonged leg cramps.",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  {
    question:
      "Do you use any of the following recreational drugs? Check all that apply.",
    subText:
      "Severe reactions may result if ED meds are used in conjunction with recreational drugs.",
    answers: [
      {
        answer: "Poppers or Rush",
        showConditional: true,
        hasWarning: true,
        warning: "This may affect your eligibility for a prescription",
      },
      {
        answer: "Amyl Nitrate or Butyl Nitrate",
        showConditional: true,
        hasWarning: true,
        warning: "This may affect your eligibility for a prescription",
      },
      {
        answer: "Cocaine",
        showConditional: true,
        hasWarning: true,
        warning: "This may affect your eligibility for a prescription",
      },
      {
        answer: "Cigarettes",
        showConditional: true,
        hasWarning: true,
        warning: "This may affect your eligibility for a prescription",
      },
      {
        answer: "Other",
        showConditional: true,
        hasWarning: true,
        warning: "This may affect your eligibility for a prescription",
      },
      {
        answer: `None of these apply`,
        showConditional: false,
      },
    ],
    type: "checkBox",
    isConditional: false,
  },
  {
    question:
      "Please explain your drug use. How frequently do you use them? When was the last time you used them?",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
    showPreviousAnswers: true,
  },
  {
    question:
      "Do you have any of the following conditions? Select all that apply.",
    answers: [
      {
        answer: `A marked curve or bend in the penis that interferes with sex, or Peyronie's Disease`,
        showConditional: true,
        condId: "curve",
      },
      {
        answer: `Pain with erections or with ejaculation`,
        showConditional: false,
      },
      {
        answer: `A foreskin that is too tight`,
        showConditional: true,
        condId: "tight",
      },
      {
        answer: `None of these apply`,
        showConditional: false,
      },
    ],
    type: "checkBox",
    isConditional: false,
  },
  {
    question: "Metaquestion",
    isConditional: true,
    subQuestions: [
      {
        question:
          "Does the mark or curve prevent you from having sex? Is it painful? Have you sought treatment for this?",
        subText:
          "If so, what kind of treatment did you receive? Please explain further.",
        answers: ["freeText"],
        type: "freeText",
        isConditional: true,
        condId: "curve",
      },
      {
        question:
          "Does your tight foreskin cause pain when you have an erection? Does this interfere with sex? Are you able to pull your foreskin back and forth over the head of your penis?",
        subText: "Please explain further.",
        answers: ["freeText"],
        type: "freeText",
        isConditional: true,
        condId: "tight",
      },
    ],
  },
  {
    question:
      "Do you have now, or have you ever had any of the following conditions? Select all that apply",
    subText:
      "This information helps your doctor provide both effective and safe dosages of medication if appropriate.",
    answers: [
      {
        answer: "Priapism (erection lasting longer than 4 hours)",
        showConditional: true,
      },
      {
        answer: "Retinitis Pigmentosa",
        showConditional: true,
      },
      {
        answer: "Neurologic disease or stroke",
        showConditional: true,
      },
      {
        answer:
          "Blood clotting disorder, abnormal bleeding or bruising, or coagulopathy",
        showConditional: true,
      },
      {
        answer: "Stomach or intestinal ulcer",
        showConditional: true,
      },
      {
        answer: "A prior heart attack or heart failure",
        showConditional: true,
        condId: "heartAttack",
      },
      {
        answer: "Peripheral artery disease",
        showConditional: true,
      },
      {
        answer: "Any history of QT prolongation",
        showConditional: true,
      },
      {
        answer: "Sickle cell anemia, Myelomia, Leukemia",
        showConditional: true,
      },
      {
        answer: "Idiopathic Hypertrophic Subaortic Stenosis",
        showConditional: true,
      },
      {
        answer: "Use of blood thinners",
        showConditional: true,
      },
      {
        answer: "None of these apply",
        showConditional: false,
      },
    ],
    type: "checkBox",
    isConditional: false,
  },
  {
    question: "Metaquestion",
    isConditional: true,
    subQuestions: [
      {
        question: "Have you had a heart attack in the last three months?",
        answers: [
          {
            answer: "Yes",
            showConditional: false,
            error: {
              header: `We recommend seeing a healthcare professional in-person to discuss whether ED medication may be right for you.`,
            },
          },
          {
            answer: "No",
            showConditional: false,
          },
        ],
        type: "radio",
        condId: "heartAttack",
        isConditional: true,
      },
      {
        question: "Please tell us more about your condition(s).",
        answers: ["freeText"],
        type: "freeText",
        isConditional: true,
        showPreviousAnswers: true,
      },
    ],
  },
  {
    question:
      "Is there anything else you want your doctor to know about your condition or health?",
    answers: [
      {
        answer: "Yes",
        showConditional: true,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question:
      "Please enter anything else you want your doctor to know about your condition or health.",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
];

// const hardStopText = "NOTE: Relivion MG may not be an appropriate therapy for your migraine. Feel free to contact <a href='https://www.relivion.com/contact/' target='_blank'>Relivion Customer Care</a> for additional questions about Relivion MG or speak with your physician about alternate treatments."

// export const questionnaire = [
//   {
//     question: "Have you been <b>diagnosed with migraine headaches</b>?",
//     answers: [
//       {
//         answer: "Yes",
//         showConditional: false,
//       },
//       {
//         answer: "No",
//         showConditional: false,
//         error: {
//           header:
//             "Relivion MG is indicated for the treatment of migraine and not other neurologic disorders.",
//           body: hardStopText,
//         },
//       },
//     ],
//     type: "radio",
//     isConditional: false,
//   },
//   {
//     question: "What is <b>your age</b>?",
//     answers: [
//       {
//         answer: "Under 18",
//         showConditional: false,
//         error: {
//           header:
//             "Relivion is not indicated for use in patients under the age of 18.",
//           body: hardStopText,
//         },
//       },
//       {
//         answer: "18-70",
//         showConditional: false,
//       },
//       {
//         answer: "70+",
//         showConditional: false,
//         hasWarning: true,
//         warning:
//           "Note: The Relivion system is a platform that requires the user to interact with an app on a smart phone as well as well as a neurostimulation device that is worn on the head. The user should be comfortable with technology & head stimulation to have the best experience.",
//       },
//     ],
//     type: "radio",
//     isConditional: false,
//   },
//   {
//     question: "How many <b>headache days</b> do you have <b>per month</b>?",
//     answers: [
//       {
//         answer: "1-7",
//         showConditional: false,
//         hasWarning: true,
//         warning:
//           "NOTE: The effects of Neuromodulation Therapy may not be felt immediately, and the lower number of migraine days may increase the time needed for you to be satisfied with the Relivion Therapy.",
//       },
//       {
//         answer: "8-15",
//         showConditional: false,
//       },
//       {
//         answer: "16-24",
//         showConditional: false,
//       },
//       {
//         answer: "25+",
//         showConditional: false,
//       },
//     ],
//     type: "radio",
//     isConditional: false,
//   },
//   {
//     question:
//       "Have you been diagnosed or are you suspected to have <b>heart disease</b> or <b>epilepsy</b>?",
//     answers: [
//       {
//         answer: "Yes",
//         error: {
//           header:
//             "Relivion® MG has not been studied in people with heart disease or epilepsy.",
//           body: hardStopText,
//         },
//         showConditional: false,
//       },
//       {
//         answer: "No",
//         showConditional: false,
//       },
//     ],
//     type: "radio",
//     isConditional: false,
//   },
//   {
//     question: "Please select your <b>sex assigned at birth</b>.",
//     answers: [
//       {
//         answer: "Female",
//         showConditional: true,
//       },
//       {
//         answer: "Male",
//         showConditional: false,
//       },
//     ],
//     type: "radio",
//     standardDemo: "sex",
//     isConditional: false,
//   },
//   {
//     question: "Are you <b>pregnant</b>?",
//     answers: [
//       {
//         answer: "Yes",
//         showConditional: false,
//         error: {
//           header:
//             "The safety of electrical stimulation during pregnancy has not been established.",
//           body: hardStopText,
//         },
//       },
//       {
//         answer: "No",
//         showConditional: false,
//       },
//     ],
//     type: "radio",
//     isConditional: true,
//   },
//   {
//     question:
//       "In the <b>past 3 months</b> have you had any <b>facial or brain trauma</b>?",
//     answers: [
//       {
//         answer: "Yes",
//         showConditional: false,
//         error: {
//           header:
//             "Persons with any facial or brain trauma in the past 3 month should not use Relivion. If you are interested in trying Relivion in the future, please visit us later.",
//           body: hardStopText,
//         },
//       },
//       {
//         answer: "No",
//         showConditional: false,
//       },
//     ],
//     type: "radio",
//     isConditional: false,
//   },
//   {
//     question: "<b>Do you have any of the following?</b>",
//     questionBulletPoints: [
//       " Implanted neurostimulator ",
//       " Cardiac pacemaker ",
//       " Defibrillator ",
//       " Implanted metallic or electronic device in your head (other than dental implants)? ",
//       " Wearable defibrillator",
//     ],
//     answers: [
//       {
//         answer: "Yes",
//         showConditional: false,
//         error: {
//           header:
//             "Persons with implanted neurostimulator, cardiac pacemaker, defibrillator or any other implanted metallic or electronic device in the head or a wearable defibrillator should not use Relivion.",
//           body: hardStopText,
//         },
//       },
//       {
//         answer: "No",
//         showConditional: false,
//       },
//     ],
//     type: "radio",
//     isConditional: false,
//   },
//   {
//     question:
//       "Do you suffer from <b>Allodynia</b>, or do you have <b>scalp sensitivity</b> during your migraine attacks?",
//     answers: [
//       {
//         answer: "Yes",
//         showConditional: false,
//         hasWarning: true,
//         warning:
//           "NOTE: The Relivion Device must be worn tightly around the head during the treatment so persons who cannot tolerate pressure on their head during a migraine may not be able to stand the Relivion Therapy",
//       },
//       {
//         answer: "No",
//         showConditional: false,
//       },
//     ],
//     type: "radio",
//     isConditional: false,
//   },
//   {
//     question: "Please list any <b>allergies</b>.",
//     answers: ["allergies"],
//     type: "freeText",
//     standardDemo: "allergies",
//     isConditional: false,
//   },
//   {
//     question:
//       "Please list any <b>medical conditions</b> and any <b>prior surgeries</b>.",
//     answers: ["medicalConditions"],
//     type: "freeText",
//     standardDemo: "medicalConditions",
//     isConditional: false,
//   },
//   {
//     question:
//       "Please list any <b>current medicines</b>, <b>vitamins</b> or <b>dietary supplements</b> you take regularly. Please include the dosage.",
//     answers: ["selfReportedMeds"],
//     type: "freeText",
//     standardDemo: "selfReportedMeds",
//     isConditional: false,
//   },
//   {
//     question:
//       "Is there <b>anything else you want your doctor to know</b> about your condition or health?",
//     answers: ["freeText"],
//     type: "freeText",
//     isConditional: false,
//   },
// ];
